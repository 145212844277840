
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import { Orientation } from '~/models/enums/orientation';
import DeviceMixin from '~/mixins/device.mixin';
import { CAROUSEL_SIZE } from '~/constants/carousel-size';
import WidgetMixin from '~/mixins/widget.mixin';

@Component({
  name: 'dn-banner-list',
})
export default class BannerList extends mixins(DeviceMixin, WidgetMixin) {
  @Prop({ type: Number, default: 2 }) cols!: number;

  private get orientation(): string {
    return (this.cols > 1 && !this.isMobile) || (this.cols > 2 && this.isMobile)
      ? Orientation.HORIZONTAL
      : Orientation.VERTICAL;
  }

  private get gridOrientation(): Record<Orientation, string> {
    return {
      [Orientation.VERTICAL]: 'flex-col justify-between space-y-6',
      [Orientation.HORIZONTAL]: 'flex-row space-x-6',
    };
  }

  private get colWidth(): Record<Orientation, string> {
    return {
      [Orientation.VERTICAL]: 'w-full',
      [Orientation.HORIZONTAL]: 'shrink-0 w-45 md:w-full md:shrink',
    };
  }

  private get bannerRatio(): number {
    return this.cols === 2 ? 588 / 330 : 1;
  }

  private get bannerMobileRatio(): number {
    return this.cols === 2 ? CAROUSEL_SIZE.MOBILE_WIDTH / 193 : 1;
  }
}
